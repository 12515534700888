import request from '@/utils/request'
// 获取list
export function getList(userId) {
  return request({
    url: 'pay/pay/billRefUser/getList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      data = JSON.stringify(data)
      return data
    }],
    data: {
      'userId': userId
    }
  })
}

// 删除
export function delBillPersonInfo(id) {
  return request({
    url: 'pay/pay/billRefUser/delBillPersonInfo',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      data = JSON.stringify(data)
      return data
    }],
    data: {
      'id': id
    }
  })
}

// 新增
export function addBillPersonInfo(data) {
  return request({
    url: 'pay/pay/billRefUser/addBillPersonInfo',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      data = JSON.stringify(data)
      return data
    }],
    data: data
  })
}

// 获取单条
export function getBillInfoRefById(id) {
  return request({
    url: 'pay/pay/billRefUser/getBillInfoRefById/' + id,
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      data = JSON.stringify(data)
      return data
    }]
  })
}

// 修改
export function updateBillPersonInfo(data) {
  return request({
    url: 'pay/pay/billRefUser/updateBillPersonInfo',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      data = JSON.stringify(data)
      return data
    }],
    data: data
  })
}
