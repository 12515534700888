<!-- 发票抬头管理 -->
<template>
  <div id="invoiceTitleList">
    <div>
      <div class="head">
        <van-nav-bar
          :title="$t('person.invoice-title')"
          :left-text="$t('main.return')"
          left-arrow
          style="background-color: rgba(255,255,255,0);"
          @click-left="onClickLeft"
        />
      </div>
    </div>
    <div class="outboxstyle">
      <div v-for="(item, index) in list" :key="index" class="box-part" @click="jumpToDetail(item.id)">
        <div class="part-left">
          <div class="part-left-img" :class="{'red':item.isDefault === '1'}">
            <van-icon v-if="item.custType === '1'" name="manager" />
            <van-icon v-if="item.custType === '2'" name="wap-home" />
          </div>
          <div class="part-left-group">
            <p class="group-title">{{ item.custName }}</p>
            <p v-if="item.custType === '2'" class="group-des">{{ item.custTaxNo }}</p>
            <p v-if="item.custType === '1'" class="group-des">{{ item.custTelephone }}</p>
          </div>
        </div>
        <van-icon name="arrow" class="part-right" />
      </div>
      <div class="new-part" @click="jumpToDetail('NO')">添加新的发票抬头</div>
    </div>
  </div>
</template>

<script>
import { getList } from '@/api/invoiceTitle'
export default {
  name: 'InvoiceTitleList',
  data() {
    return {
      list: [
        // {
        //   custName: '这是默认', // 购方名称
        //   custTaxNo: '这是默认描述', // 纳税人识别号
        //   custTelephone: '', // 电话
        //   isDefault: 1, // 是否默认
        //   id: 1,
        //   custType: '2' // 1是个人 2是单位
        // }
      ]
    }
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background: #F5F5F5')
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      getList(window.localStorage.getItem('userId')).then(res => {
        this.list = res.data
      })
    },
    // 跳转
    jumpToDetail(id) {
      this.$router.push({ name: 'invoiceTitleDetail', params: { id: id }})
    },
    // 返回
    onClickLeft() {
      this.$router.go(-1)
    }
  }
}
</script>
<style scoped lang="scss">
.red{
    background-color: #F96662 !important;
}
#invoiceTitleList {
  position: relative;
  background-color: #eff6ff;
  .head {
    height: 86px;
    width: 100%;
    background:linear-gradient(135deg, #3793e5 0%, #4046a6 100%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.outboxstyle {
  padding: 15px 12px;
  background-color: #F5F5F5;
  border-radius: 10px;
  margin-top: -20px;
  width: 100%;
  height: 100%;
}
.boxstyle {
  margin: 10px 15px 0 15px;
  padding:20px 0;
  background-color: #ffffff;
  border: 1px white solid;
  border-radius: 10px 10px 0 0;
  box-shadow: 0px 4px 10px #dde9f3;
}
.new-part{
    height: 66px;
    background: #F5F5F5;
    border-radius: 4px;
    border: 1px solid rgba(36, 159, 254, 0.2);
    line-height: 66px;
    text-align: center;
    font-size: 14px;
    color: #249FFE;
}
.box-part{
    background-color: #fff;
    border-radius: 8px;
    height: 66px;
    padding: 0 10px 0 15px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.part-left{
    height: 35px;
    display: flex;
    .part-left-img{
        width: 35px;
        line-height: 35px;
        background-color: #2F9FF2;
        border-radius: 3px;
        color: #fff;
        text-align: center;
        i{
            font-size: 25px;
        }
    }
}
.part-left-group{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 13px;
    .group-title{
        font-size: 14px;
        color: #2e2e2e;
    }
    .group-des{
        font-size: 12px;
        color: #747474;
    }
}
.part-right{
    font-size: 20px;
    color: #f0f0f0;
}
</style>
<style>
.van-nav-bar .van-icon {
    color: #ffffff;
}
.van-nav-bar__title {
    color: #ffffff;
}
.van-nav-bar__text {
    color: #ffffff;
}
[class*='van-hairline']::after {
    border: none;
}
.van-nav-bar {
  height: 80px;
}
</style>
